<template>
  <div >
    <div style="font-size:0">
      <img src="../assets/solutionimg/banner.png" width="100%">
    </div>

    <div class="solutions" >
      <div class="location">
        <img src="../assets/solutionimg/solimgone.png">
      </div>
    </div>

      <div class="industry">
       <p class="title">行业解决方案</p>
            <div class="indu"  @mouseover="changeInterval(true)"
            @mouseleave="changeInterval(false)">
            <div v-for="(item,index) in subjectList" :key="index" class="figure" @click="switchover(item,index)" :class="{active : item.id === activeId}">
            <img :src="item.id === activeId ? item.activity : item.tubiao" >
             <span>{{item.title}}</span>
           </div>
           </div>
           <div class="cut-off">
               <hr>
           </div>

           <div class="backdrop" @mouseover="changeInterval(true)"
            @mouseleave="changeInterval(false)" >
            <img src="../assets/solutionimg/solimgtwo.png"  class="left-img" @click="leftClick">
           <img :src="content[activeId]" class="centre-img"  >
           <img src="../assets/solutionimg/solimgthere.png" class="left-img" @click="rightClick">
           </div>
      </div>

      <div class="serve">
        <p>流程服务</p>
        <img src="../assets/solutionimg/solimgfour.png">
      </div>
      </div>

</template>

<script>
export default {
  data () {
    return {
      activeId: 'a', // 默认显示第一页
      activeIndex: 0,
      timer: null,
      subjectList: [
        {
          id: 'a',
          title: '数字通信',
          tubiao: require('@/assets/solutionimg/figure2.png'),
          active: require('@/assets/solutionimg/solimgfive.png'),
          activity: require('@/assets/solutionimg/11.png')
        },
        {
          id: 'b',
          title: '数字城市',
          tubiao: require('@/assets/solutionimg/figure1.png'),
          active: require('@/assets/solutionimg/solimgsix.png'),
          activity: require('@/assets/solutionimg/4.png')
        },
        {
          id: 'c',
          title: '数字电力',
          tubiao: require('@/assets/solutionimg/figure3.png'),
          active: require('@/assets/solutionimg/solimgseven.png'),
          activity: require('@/assets/solutionimg/3.png')
        },
        {
          id: 'd',
          title: '数字制造',
          tubiao: require('@/assets/solutionimg/figure4.png'),
          active: require('@/assets/solutionimg/solimgeight.png'),
          activity: require('@/assets/solutionimg/2.png')
        }
      ],
      content: {
        a: require('@/assets/solutionimg/solimgfive.png'),
        b: require('@/assets/solutionimg/solimgsix.png'),
        c: require('@/assets/solutionimg/solimgseven.png'),
        d: require('@/assets/solutionimg/solimgeight.png')
      }
    }
  },
  // 进入页面后启动定时轮询
  mounted () {
    this.startInterval()
  },
  methods: {
    switchover (item, index) {
      this.activeIndex = index
      this.activeId = item.id
    },
    leftClick () {
      if (this.activeIndex <= 0) {
        this.activeIndex = 0
        this.activeId = 'a'
        return
      }
      this.activeIndex = this.activeIndex - 1
      this.activeId = this.subjectList[this.activeIndex].id
    },
    rightClick () {
      if (this.activeIndex === this.subjectList.length - 1) {
        // this.activeIndex = this.subjectList.length - 1
        // this.activeId = this.subjectList[this.activeIndex].id
        this.activeIndex = 0
        this.activeId = 'a'
        return
      }
      this.activeIndex = this.activeIndex + 1
      this.activeId = this.subjectList[this.activeIndex].id
    },
    // 开启定时器
    startInterval () {
      clearInterval(this.timer)
      this.timer = setInterval(() => {
        if (this.activeIndex === this.subjectList.length) {
          this.activeIndex = 0
          this.activeId = 'a'
        }
        this.activeId = this.subjectList[this.activeIndex].id
        this.activeIndex++
      }, 2000)
    },
    // 鼠标移入移出控制
    changeInterval (val) {
      if (val) {
        clearInterval(this.timer)
      } else {
        this.startInterval()
      }
    }
  }

}
</script>

<style lang="scss" scoped>

.solutions{
  position: relative;
   text-align: center;
   height: 160px;
   width: 100%;
    .location{
        width: 100%;
         position: absolute;
         top: -90px;
         display: flex;
         justify-content: center;
         img{
           width: 1400px;
         }
    }
}

.industry{
  margin-top: 84px;
  .title{
    font-size: 36px;
    font-family: Microsoft YaHei;
    font-weight: bold;
    color: #000000;
    margin-bottom: 79px;
    text-align: center;
  }
  .cut-off{
    display: flex;
    justify-content: center;
    hr{
    width: 1398px;
   border: 1px solid #E3E8F7;
   opacity: 1;
  }
  }

}
.indu{
  display: flex;
  justify-content: center;
  .figure{
    width: 287px;
    height: 154px;
    display: flex;
   justify-content: center;
   flex-direction: column;
    align-items: center;
    cursor: pointer;
  span{
   font-size: 24px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    margin-top: 5px;
  }
   img{
     width: 65px;
     height: 65px;
   }
  }
}

.backdrop{
    width: 100%;
    height: 490px;
    background: linear-gradient(180deg, #FFFFFF 0%, #E6EFFF 100%);
    opacity: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    // padding-top: 104px;
      .centre-img{
      width: 1318px;
      height: 335px;
      margin: 0 85px 0 85px;
      }
   .left-img{
   width: 17px;
   height: 32px;
   cursor: pointer;
 }
}
 .active{
   background: #FFF1E6;
   color: #FF892E;
 }

.serve{
  text-align: center;
  margin: 87px 0 104px 0;
    p{
    font-size: 36px;
    font-family: Microsoft YaHei;
    font-weight: bold;
    color: #000000;
    margin-bottom: 74px;
    }
    img{
     width: 1357px;
     height: 374px;
    }
  }
</style>
